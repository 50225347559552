import React, { Component, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import { RecoilRoot } from "recoil";
import ContactUs from "./views/MainPage/ContactUs";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ChangePassword = React.lazy(() =>
  import("./views/pages/changePassword/ChangePassword")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Instruction = React.lazy(() =>
  import("./views/pages/registration/instruction")
);
const OnboardHome = React.lazy(() => import("./views/MainPage/OnboardHome"));
const OnboardRegisteration = React.lazy(() =>
  import("./views/MainPage/OnboardRegisteration")
);
const OnboardVerifyOTP = React.lazy(() =>
  import("./views/MainPage/OnboardVerifyOTP")
);
const Registration = React.lazy(() =>
  import("./views/MainPage/Registration.js")
);
const ForgotPassword = React.lazy(() =>
  import("./views/pages/ForgotPassword/ForgotPassword")
);

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <RecoilRoot>
            {" "}
            <Routes>
            <Route
                exact
                path="/onboardRegisteration"
                name="Form Page"
                element={<OnboardRegisteration />}
              />
              <Route
                exact
                path="/contact-us"
                name="Contact Us"
                element={<ContactUs />}
              />        
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
             
              <Route
                exact
                path="/onboardVerifyOTP"
                name="Verify OTP Page"
                element={<OnboardVerifyOTP />}
              />
            </Routes>
          </RecoilRoot>
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
