import { useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Heading from "./Heading";
import { useEffect, useState } from "react";

export default function ContactUs() {
  const [url, setUrl] = useState('https://docs.google.com/forms/d/e/1FAIpQLSeECQn5FPNmAa2YyZPmYR8sh0nkg60kP-jV2sepXnJG433PDw/viewform?usp=sf_link');
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const  state  = location.state;
    if(state)
      setUrl(state) 
  }, []);

  const handleBackButton = (event) => {
    event.preventDefault();
    navigate('/onboardRegisteration'); // replace '/previous-url' with the desired URL
  };

  const handleCheckboxChange1 = (event) => {
    setIsCheck(event.target.checked);
  };
  return (
    <>
      <Heading />
      <div className="card m-3"  >
        <div
          className="card-header bg-white text-black"
          style={{ fontSize: "25px" }}
        >
          <b>Contact Us</b>
        </div>
        <div className="card-body">
            <div className="row">
            <div className="col-sm-12" style={{ fontSize: "18px" }}>
              {/* <b>How to Contact?</b>
           
            <hr /> */}
             
                <>
                  <a
                    target="_blank"
                    href={url}
                    style={{ textDecoration: "none" }}
                  >
                    <u><b>Click here to submit your query.</b></u>
                  </a>
                  <br />
                  <span className="text-danger">
                    <b>
                      Please make sure you enter below details while raising the
                      request
                    </b>
                  </span>
                  <ul>
                    <li>Group C CET registration number</li>
                    <li>Name of the candidate</li>
                    <li>
                      Details of the issue, along with relevant screen shots.{" "}
                    </li>
                  </ul>
                 <hr style={{width:"100%"}}/>
                 <>
                      Please feel free to call us at the numbers provided if
                      your question is still unanswered.
                      <br />
                      Phone - 18005728997 (on all working days from 10 AM to 5
                      PM)
                      <br />
                      Phone - 7428944699 (For technical issues on all working
                      days from 9 AM to 6 PM)
                      <br />
                    </>            
        
                </>
                </div>           
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center" style={{marginBottom: 140}}>
        <button className="btn btn-success text-white" onClick={handleBackButton}>{"Go to Homepage"}</button>
      </div>
      <Footer
      url={url} />
    </>
  );
}
